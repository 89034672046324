import { defineStore } from 'pinia'
import { useUserInfo } from './useInfo'
import { useTeamInfo } from './team'
import { getAllConversationTagsFn } from '@/api/message/conversationTag'
import {
  getConvListFn,
  getQueueListFn,
  getFinishedListFn,
  finishConvFn,
  finishQueueFn,
  connectConvFn,
  connectQueueFn,
  getConvOnlineFn,
  getConvInfoFn,
  getConvDetailsFn,
  getConvMsgWithHistoryFn,
  addConversationTagsFn,
  readConvFn
} from '@/api/message/conversations'
import { subscribeFn, unsubscribe } from '@/composables/socketEvents'

export const useConvInfo = defineStore('convInfo', {
  state: () => ({
    filterViewConvAgentIds: [], // 獲取當前查看對象的對話列表
    filterViewConvMode: '' || 'agent',

    assignList: [], // 對話列表(已分配)
    assignListIds: new Set(),
    storageAssignList: [],

    unassignList: [], // 對話列表(未分配)
    unassignListIds: new Set(),
    storageUnassignList: [],

    queueList: [], // 排隊列表
    queueListIds: new Set(),
    storageQueueList: [],

    finishedList: [], // 已結束列表
    finishedListIds: new Set(),
    storageFinishedList: [],

    topConvList: [], // 置頂對話列表
    topConvListIds: new Set(),

    transferDlgVisible: false,
    transferDlgInfo: [],

    blacklistDlgVisible: false,
    blacklistDlgInfo: [],

    convListProgressLoading: false,

    currentTabStatus: '', // 當前停留的tab(預設對話中) assigned:對話中 unassigned:未分配 finished:已結束
    tabActiveMap: new Map(), // 當前tab內查看的對話id { assigned: '', unassigned: '', finished: '' }

    currentChatChannel: {}, // 當前對話【渠道資訊】
    currentChatConversation: {}, // 當前對話【對話資訊】
    currentChatMsgList: [], // 當前對話【訊息列表】
    currentChatCustomer: {}, // 當前對話【訪客】
    currentChatShowMoreBtn: false, // 當前對話【顯示查看歷史】

    nextConversationId: '', // 請求下一筆資料【id】
    nextSequenceId: '', // 請求下一筆資料【序號】
    prevConversationId: '', // 請求上一筆資料【id】
    prevSequenceId: '', // 請求上一筆資料【序號】
    lastMsgId: null, // 用來找出往後放的資料要從哪則訊息插入
    isScrollGetHistoryMsg: false, // 捲軸是否加載歷史訊息
    compareMsgIdIsRepeat: null, // 加載歷史訊息是否重複

    currentChatTypingContent: '', // 當前對話消息預知
    currentSubscribeConvInfo: {}, // 當前訂閱的對話

    isScrollAtBottom: true, // 卷軸是否在最底部
    unreadMsgNum: 0, // 當前對話未讀訊息
    scrollToBottom: 0, // 滾動到底部

    pageSize: 20, // 獲取對話歷史訊息條數
    customerModeType: 'edit',

    quickReplyMsg: '',
    conversationTags: []
  }),
  getters: {
    assignListUnreadTotalFn (state) {
      const total = (state.assignList || []).reduce((sum, item) => sum + item.unreadCount, 0)
      return total
    },
    unassignListUnreadTotalFn (state) {
      const total = (state.unassignList || []).reduce((sum, item) => sum + item.unreadCount, 0)
      return total
    },
    totalUnreadCountFn (getters) {
      // side menu 未讀訊息
      return getters.assignListUnreadTotalFn + getters.unassignListUnreadTotalFn
    }
  },
  actions: {
    // 獲取對話列表 根據當前tab
    getConvListByTab (tab) {
      tab = tab || this.currentTabStatus
      if (tab === 'assigned') {
        this.getConvList()
      } else if (tab === 'unassigned') {
        this.getQueueList()
        this.getConvList()
      } else if (tab === 'finished') {
        this.getFinishedList()
      }
    },
    // 獲取對話列表
    async getConvList (status) {
      const userInfo = useUserInfo()

      const mode = this.filterViewConvMode // agent:自己 all:全部
      let agentIds = this.filterViewConvAgentIds.length > 0 ? this.filterViewConvAgentIds : [userInfo.userInfoData.id]
      if (mode === 'all' || (agentIds.length === 1 && agentIds[0] === userInfo.userInfoData.id)) {
        agentIds = null
      }

      const params = {
        mode,
        ...(agentIds !== null && { agentIds })
      }
      const res = await getConvListFn(params)

      const data = res.data.value
      if (data.code === 200) {
        this.assignList = [] // 清空列表(已分配)
        this.assignListIds.clear()
        this.storageAssignList = [] // 清空列表(已分配)

        this.unassignList = [] // 清空列表(未分配)
        this.unassignListIds.clear()
        this.storageUnassignList = [] // 清空列表(未分配)

        this.topConvList = [] // 清空置頂
        this.topConvListIds.clear()

        this.convListProgressLoading = false

        if (data.data.length <= 0) { return }

        data.data.forEach((conv) => {
          conv.onActive = false
          this.pushConvToList(conv)
        })

        // active當前對話
        const findArray = (array) => {
          const index = array.findIndex(i => i.id === this.currentChatConversation?.id)
          if (index >= 0) {
            array[index].onActive = true
          }
        }
        const isValidList = list => list && list.length > 0
        if (this.currentTabStatus === 'assigned') {
          // 對話中
          if (!isValidList(this.assignList) && !isValidList(this.topConvList)) { return }
          findArray(this.topConvList)
          findArray(this.assignList)
        } else {
          // 已結束
          if (!isValidList(this.finishedList)) { return }
          findArray(this.finishedList)
        }
      }
    },
    // 獲取排隊列表
    async getQueueList (pageReq) {
      const params = {
        pageNum: pageReq?.pageNum || 0,
        pageSize: pageReq?.pageSize || 100
      }
      const res = await getQueueListFn(params)
      const data = res.data.value
      if (data.code !== 200) { return }

      // 清空排隊列表
      this.queueList = []
      this.queueListIds.clear()
      this.storageQueueList = []

      data.data.data.forEach((conv) => {
        conv.status = 'in_queue'
        this.pushConvToList(conv)
        this.processCustomerTags(conv)
      })
    },
    // 獲取已結束列表
    async getFinishedList (pageReq) {
      const params = {
        pageNum: pageReq?.pageNum || 0,
        pageSize: pageReq?.pageSize || 100
      }
      const res = await getFinishedListFn(params)
      const data = res.data.value
      if (data.code !== 200) { return }

      // 清空已結束列表
      this.finishedList = []
      this.finishedListIds.clear()
      this.storageFinishedList = []

      data.data.data.forEach((conv) => {
        conv.status = 'finished'
        this.pushConvToList(conv)
      })
    },
    // 轉接
    async transferConv (info) {
      const teamInfo = useTeamInfo()

      this.transferDlgVisible = true
      this.transferDlgInfo = info

      // 獲取客服列表
      const params = {
        pageNum: 0,
        pageSize: 100
      }
      await teamInfo.getDeptDetail(params)
    },
    // 拉黑
    blockConv (info) {
      this.blacklistDlgVisible = true
      this.blacklistDlgInfo = info
    },

    // 結束對話
    async endConv (conv) {
      if (conv.status === 'in_queue') {
        this.endQueue(conv)
      } else {
        await finishConvFn(conv.id)
      }
    },
    // 結束排隊
    async endQueue (conv) {
      const res = await finishQueueFn(conv.customerId, conv.channelId)
      this.getConvAndRemoveFromList(conv.id, this.queueList, this.queueListIds)
    },
    async getOnlineConvInfo (convId) { // 獲取在線對話資訊 (未分配、已分配)
      const params = {
        convId,
        pageSize: this.pageSize
      }
      const res = await getConvOnlineFn(params)
      const data = res.data.value
      if (data.code === 200) {
        return data.data
      }
    },
    async getConvInfo (convId) { // 獲取對話資訊 (已結束)
      const params = {
        convId,
        pageSize: this.pageSize
      }
      // TODO 需等候端修改 getConvInfo，修改完要換回 getConvInfo 這個 API，以及 customerInfo.vue getCustomerContent()裡的 API 要換
      const res = await getConvDetailsFn(params)
      const data = res.data.value
      if (data.code === 200) {
        return data.data
      }
    },
    async getHistoryMsgFn (convId, sequenceId, direction) { // 獲取對話歷史訊息
      const params = {
        convId,
        sequenceId,
        direction: direction || 'before', // (before: 往前, after: 往後)
        pageSize: this.pageSize
      }
      const res = await getConvMsgWithHistoryFn(params)
      const data = res.data.value
      if (data.code === 200) {
        const msgObj = data.data

        const msgList = msgObj.messageList
        msgList.forEach((item) => {
          this.processMsg(item) // 處理訊息
          this.pushMessage(item) // 接收訊息
        })
        // 判斷顯示【加載先前對話】
        this.currentChatShowMoreBtn = msgObj.hasNext

        // 儲存下次請求的資料
        if (params.direction === 'after') {
          this.nextConversationId = msgObj.prevConversationId
          this.nextSequenceId = msgObj.sequenceId
          this.lastMsgId = msgObj.messageList.slice(-1)[0].id
        } else {
          this.prevConversationId = msgObj.prevConversationId
          this.prevSequenceId = msgObj.sequenceId
        }

        return msgObj
      }
    },
    async getConversationTags (params) { // 獲取對話標籤
      const param = {
        pageNum: params.pageNum || 1,
        pageSize: params.pageSize || 10
      }
      const res = await getAllConversationTagsFn(param)
      const data = res.data.value
      if (data.code === 200) {
        this.conversationTags = data.data.data
      }
      return this.conversationTags
    },
    compareArray (arr1, arr2) { // 比對兩個陣列是否相同
      return arr1.length === arr2.length && new Set(arr1).size === new Set(arr2).size &&
          [...new Set(arr1)].every(id => new Set(arr2).has(id))
    },
    async updateConvTags (item, data, isAdd, type) { // 更新對話標籤
      const { $i18n } = useNuxtApp()
      const editTagSucText = $i18n.t('stores.editTagSucMsg')
      const delTagSucText = $i18n.t('stores.delTagSucMsg')

      const postData = data.map(i => i.id)

      const res = await addConversationTagsFn(item.id, postData)
      if (res.data.value.code === 200) {
        ElMessage.success({ message: isAdd ? editTagSucText : delTagSucText })

        if (type === 'conv') {
          this.currentChatConversation.conversationTags = data
        }
      }
    },
    addConvTags (tags, type) { // 新增對話標籤
      const oldTags = this.currentChatConversation.conversationTags ? this.currentChatConversation.conversationTags : []

      const itemTagIds = oldTags.map(tag => tag.id)
      const tagsIds = tags.map(tag => tag.id)

      if (this.compareArray(itemTagIds, tagsIds)) {
        return
      }
      this.updateConvTags(this.currentChatConversation, tags, true, type)
    },
    removeConvTag (tag, type) { // 刪除對話標籤
      const updateTags = this.currentChatConversation.conversationTags.filter(item => item.id !== tag.id)
      this.updateConvTags(this.currentChatConversation, updateTags, false, type)
    },
    pushConvToList (conv) { // 更新對話列表(重整、進線)
      const userInfo = useUserInfo()
      // filter 過濾列表
      const filterConv = this.filterList(conv)
      if (!filterConv) { return }

      let targetList = null
      let targetSet = null

      switch (filterConv.status) { // 已分配、未分配、排隊
        case 'assigned':

          // 先從未分配列表中刪除
          if (this.unassignListIds.has(filterConv.id)) {
            this.getConvAndRemoveFromList(filterConv.id, this.unassignList, this.unassignListIds)
          }

          // 判斷是否是正在查看客服的對話
          if (this.filterViewConvMode !== 'all') {
            const filterAgentIds = this.filterViewConvAgentIds.length > 0 ? this.filterViewConvAgentIds : [userInfo.userInfoData.id]
            if (!filterAgentIds.includes(filterConv.agentId)) { return }
          }

          targetList = this.storageAssignList
          targetSet = this.assignListIds
          break
        case 'unassigned':
          targetList = this.storageUnassignList
          targetSet = this.unassignListIds
          break
        case 'in_queue':
          targetList = this.storageQueueList
          targetSet = this.queueListIds
          break
        case 'finished':
          targetList = this.storageFinishedList
          targetSet = this.finishedListIds
          break
        default:
          break
      }

      // 去重
      if (targetSet.has(filterConv.id)) { return }

      // 處理資料 ---

      // 取得客服、渠道名稱、客戶標籤
      this.processAgentName(filterConv, filterConv.agentId)
      this.processLastSenderAgentName(filterConv, filterConv.agentId)
      this.processChannelName(filterConv)
      this.processCustomerTags(filterConv)

      // 統一型別
      filterConv.createdAt = Number(filterConv.createdAt) || null
      filterConv.endedAt = Number(filterConv.endedAt) || null
      filterConv.lastMessageAt = Number(filterConv.lastMessageAt) || null
      filterConv.customerWaitReplyAt = Number(filterConv.customerWaitReplyAt) || null
      filterConv.isTop = false
      filterConv.onActive = this.currentChatConversation?.id === filterConv.id

      // 更新對話列表 ---

      if (filterConv.status === 'assigned') {
        // 置頂
        const getConvListTop = localStorage.getItem('convListTop') || null
        const convListTop = JSON.parse(getConvListTop) || []
        const isTopIndex = convListTop.indexOf(filterConv.id)
        if (isTopIndex !== -1) {
          // 置頂對話
          filterConv.isTop = true
          this.topConvListIds.add(filterConv.id) // 加到去重列表
          this.topConvList.splice(isTopIndex, 0, filterConv)
        } else {
          targetSet.add(filterConv.id) // 加到去重列表
          targetList.push(filterConv)
        }
      } else {
        targetSet.add(filterConv.id) // 加到去重列表
        targetList.push(filterConv)
      }

      // 排序
      this.setConvSort()
    },
    filterList (conv) {
      // 取得localStorage設定值
      const config = this.getConvSettingsStorage()
      if (Object.values(config).length <= 0) {
        return conv
      }

      // 取得過濾值
      const { channelType, customerTags, keyword, setConvList } = config

      // 對話列表顯示
      conv.setConvList = setConvList

      // 確認有無篩選條件，若不用直接返回conv
      const hasFilter = (!channelType || channelType.length <= 0) && (!customerTags || customerTags.length <= 0)
      if (hasFilter) {
        return conv
      }

      const filters = [
        // 接入渠道過濾
        channelType.includes(conv.channelType),

        // 客戶標籤過濾
        customerTags.some(tag => conv.customerTagIds?.includes(tag))

        // TODO 模糊搜尋過濾（若需要，這裡可以加入 keyword 判斷）
        // keyword
        //   ? (conv.title?.includes(keyword) || conv.content?.includes(keyword))
        //   : true
      ]

      return filters.some(Boolean) ? conv : null
    },
    processCustomerTags (conv) { // 獲取客戶標籤
      const customerCenterInfo = useCustomerCenterInfo()
      if (conv?.customerTagIds && customerCenterInfo.customerTagsMap) {
        const tags = []
        conv.customerTagIds.forEach((i) => {
          const getTagName = customerCenterInfo.customerTagsMap.get(i)
          if (getTagName) {
            tags.push(getTagName.name)
          }
        })
        conv.customerTagNames = tags
      }
    },
    processAgentName (conv, agentId) { // 獲取客服名稱
      const userInfo = useUserInfo()
      if (agentId) {
        conv.agentName = userInfo.agentUsersMap?.get(agentId)?.username
        conv.avatar = userInfo.agentUsersMap?.get(agentId)?.avatar
      }
    },
    processLastSenderAgentName (conv, agentId) { // 獲取最後發送者若為客服的名稱
      const userInfo = useUserInfo()
      if (conv.lastSenderType && conv.lastSenderType === 'agent' && agentId) {
        conv.lastSenderName = userInfo.agentUsersMap?.get(agentId)?.username
        conv.lastSenderAnonymous = false
      }
    },
    processChannelName (conv) { // 獲取渠道名稱
      const channelInfo = useChannelInfo()
      if (conv?.channelId) {
        conv.channel = channelInfo.channelMap?.get(conv.channelId)
      }
    },
    getConvAndRemoveFromList (convId, list, set) { // 獲取對話並從列表中刪除
      if (!set.has(convId)) { return }

      let conv = null

      // 刪除列表
      const index = list.findIndex(item => item.id === convId)
      if (index !== -1) {
        conv = list[index]
        list.splice(index, 1)
      }

      // 刪除去重列表
      set.delete(convId)

      // 若是置頂，刪除置頂
      const getConvListTop = localStorage.getItem('convListTop') || null
      const convListTop = JSON.parse(getConvListTop) || []
      const isTopIndex = convListTop.indexOf(convId)
      if (isTopIndex !== -1) {
        convListTop.splice(isTopIndex, 1)
        localStorage.setItem('convListTop', JSON.stringify(convListTop))
      }

      return conv
    },
    setConvSort () { // 排序列表
      const convListConfig = this.getConvSettingsStorage()
      const currentSort = convListConfig?.sort || 'latestReply'

      switch (currentSort) {
        case 'latestReply':
          // 最新回復
          this.assignList = this.storageAssignList.sort((a, b) => a.lastMessageAt - b.lastMessageAt)
          this.unassignList = this.storageUnassignList.sort((a, b) => a.lastMessageAt - b.lastMessageAt)
          this.queueList = this.storageQueueList.sort((a, b) => a.lastMessageAt - b.lastMessageAt)
          this.finishedList = this.storageFinishedList.sort((a, b) => a.lastMessageAt - b.lastMessageAt)
          break
        case 'waitingTime':
          // 等待時間
          this.assignList = this.storageAssignList.sort((a, b) => a.createdAt - b.createdAt)
          this.unassignList = this.storageUnassignList.sort((a, b) => a.createdAt - b.createdAt)
          this.queueList = this.storageQueueList.sort((a, b) => a.createdAt - b.createdAt)
          this.finishedList = this.storageFinishedList.sort((a, b) => a.createdAt - b.createdAt)
          break
        case 'incomingTime':
          // 進線時間
          this.assignList = this.storageAssignList.sort((a, b) => a.createdAt - b.createdAt)
          this.unassignList = this.storageUnassignList.sort((a, b) => a.createdAt - b.createdAt)
          this.queueList = this.storageQueueList.sort((a, b) => a.createdAt - b.createdAt)
          this.finishedList = this.storageFinishedList.sort((a, b) => a.createdAt - b.createdAt)
          break
        default:
          break
      }
    },
    getDefaultConvAndRefreshCurrentChat (getFirst) { // 獲取預設對話並刷新當前對話
      let listItem = null
      if (this.currentTabStatus === 'unassigned') { // 未分配頁
        listItem = this.tabActiveMap.get('unassigned')
          ? this.unassignList.find(i => i.id === this.tabActiveMap.get('unassigned'))
          : null
        if (!listItem && getFirst) {
          listItem = this.queueList.length > 0 ? this.queueList[0] : this.unassignList[0]
        }
      } else if (this.currentTabStatus === 'assigned') {
        listItem = this.tabActiveMap.get('assigned')
          ? this.assignList.find(i => i.id === this.tabActiveMap.get('assigned'))
          : null
        if (!listItem && getFirst) {
          listItem = this.topConvList.length > 0 ? this.topConvList[0] : this.assignList[0]
        }
      } else if (this.currentTabStatus === 'finished') {
        listItem = this.tabActiveMap.get('finished')
          ? this.finishedList.find(i => i.id === this.tabActiveMap.get('finished'))
          : null
        if (!listItem && getFirst) {
          listItem = this.finishedList[0]
        }
      }

      // 切換對話
      if (!listItem) {
        this.clearCurrentChat()
        return
      }

      this.changeActiveConv(listItem)
    },
    async changeActiveConv (item) { // 列表切換
      let convData = null
      let targetList = null
      switch (item.status) {
        case 'assigned':
          if (this.currentTabStatus !== 'assigned') {
            return
          }
          convData = await this.getOnlineConvInfo(item.id)
          targetList = [this.topConvList, this.assignList]
          break
        case 'unassigned':
          if (this.currentTabStatus !== 'unassigned') {
            return
          }
          convData = await this.getOnlineConvInfo(item.id)
          targetList = [this.unassignList]
          break
        case 'in_queue':
          if (this.currentTabStatus !== 'unassigned') {
            return
          }
          convData = item
          targetList = [this.queueList]
          break
        case 'finished':
          if (this.currentTabStatus !== 'finished') {
            return
          }
          convData = await this.getConvInfo(item.id)
          targetList = [this.finishedList]
          break
      }

      if (!convData) { return }

      // 刷新當前對話
      this.refreshCurrentChat(convData)

      const isAssigned = item.status === 'assigned'
      if (isAssigned) {
        // 訂閱對話
        const params = {
          conversationId: item.id,
          tenantId: item.tenantId
        }
        this.subscribeConv(params)

        // 已讀會話
        if (convData.chatConversation.unreadCount > 0) {
          this.readConv(item.id)
        }
      }

      // 重置list的active和未讀數量
      this.resetActiveAndUnreadCount(convData.chatConversation.id, isAssigned, ...targetList)

      // 儲存當前tab內查看的對話id
      this.tabActiveMap.set(this.currentTabStatus, item.id)

      // this.tabActiveMap 保存到 localStorage
      this.convSettingsStorage({ tabActiveMapValue: this.tabActiveMap, reloadConvList: false })
    },
    refreshCurrentChat (data, direction) { // 刷新當前對話
      if (!data) { return }

      if (this.currentChatConversation.id !== data.chatConversation.id) {
        this.clearCurrentChat()
      }

      const { channel, chatConversation, chatMessageList, customer } = data

      // 渠道資料
      this.currentChatChannel = channel
      // 對話資料
      this.currentChatConversation = chatConversation
      // 對話訊息
      const msgList = chatMessageList.messageList
      msgList.forEach((item) => {
        this.processMsg(item) // 處理訊息
        this.pushMessage(item) // 接收訊息
      })
      // 判斷顯示【加載先前對話】
      this.currentChatShowMoreBtn = chatMessageList.hasNext

      this.compareMsgIdIsRepeat = chatMessageList.messageList[0].id

      if (direction === 'after') {
        this.nextConversationId = chatMessageList.prevConversationId
        this.nextSequenceId = chatMessageList.sequenceId
        this.lastMsgId = chatMessageList.messageList.slice(-1)[0].id
      } else {
        this.prevConversationId = chatMessageList.prevConversationId
        this.prevSequenceId = chatMessageList.sequenceId
      }

      // 訪客資料
      this.currentChatCustomer = customer
      this.customerModeType = customer === null ? 'add' : 'edit'
    },
    clearCurrentChat () { // 清空當前對話
      this.currentChatChannel = {}
      this.currentChatConversation = {}
      this.currentChatMsgList = []
      this.currentChatCustomer = {}
      this.currentChatShowMoreBtn = false
      this.nextConversationId = ''
      this.nextSequenceId = ''
      this.prevConversationId = ''
      this.prevSequenceId = ''
      this.lastMsgId = null
      this.currentChatTypingContent = ''
      this.isScrollAtBottom = true
      this.unreadMsgNum = 0
      this.scrollToBottom = 0
      this.unsubscribeConv()
    },
    // 重置list的active和未讀數量
    resetActiveAndUnreadCount (convId, isAssigned, ...lists) {
      lists.forEach(list => list.forEach((i) => {
        i.onActive = false
        if (i.id === convId) {
          i.onActive = true
          if (isAssigned) { // 已分配才重置未讀數量
            i.unreadCount = 0
          }
        }
      }))
    },
    processMsg (item) { // 處理訊息
      const userInfo = useUserInfo()
      const conversationInfo = useConversationInfo()

      // 區分客服、訪客
      if (item.senderType === 'customer') {
        item.group = 'customer'
      } else if (item.senderType === 'system' && item.msgType === 'welcome') {
        item.group = 'center'
      } else {
        item.group = 'agent'
      }

      // 取得客服名稱
      this.processAgentName(item, item.senderId)

      // 頭像 (1.常見問題(Q) 2.系統回復(A) 3.客服頭像 4.訪客sender頭像 5.訪客頭像 )
      // TODO 優化寫法
      const avatar =
        item.msgType === 'common_problem'
          ? item.group === 'agent'
            ? '/images/answer.png'
            : '/images/question.png'
          : item.group === 'agent'
            ? userInfo.domain + item.avatar
            : item?.senderAvatar
              ? userInfo.domain + item.senderAvatar
              : this.currentChatConversation?.customerAvatar
                ? userInfo.domain + this.currentChatConversation.customerAvatar
                : '/images/test/female.png'
      item.msgAvatar = avatar

      // 名稱 (1.常見問題 2.系統回復 3.客服名稱 4.匿名 5.訪客sender名稱 6.訪客名稱)
      // TODO 優化寫法
      const name = item.msgType === 'common_problem'
        ? item.group === 'agent'
          ? '系統回復'
          : '常見問題'
        : item.group === 'agent'
          ? item.agentName
          : item?.senderAnonymous
            ? '匿名'
            : item?.senderName
              ? item.senderName
              : this.currentChatConversation?.customerName
      item.msgName = name

      // 處理引用訊息名稱
      const customerName = this.currentChatConversation?.customerName
      conversationInfo.processReplyMsg(item, customerName)
    },
    pushMessage (item, isNewMessage) { // 接收訊息
      const msgList = this.currentChatMsgList

      // 1. 比對 msg.requestId，如果相同就替換
      const index = msgList.findIndex(i => i.requestId === item.requestId)
      if (index >= 0) {
        // 替換已有訊息
        msgList[index] = item
        msgList.sort((a, b) => a.createdAt - b.createdAt)
      }

      // 2. list去重，檢查是否已存在相同id的訊息
      const isDuplicate = msgList.some(msg => msg.id === item.id) // list去重
      if (!isDuplicate) {
        if (msgList.length === 0 || msgList[msgList.length - 1].createdAt < item.createdAt) {
          msgList.push(item)
        } else {
          msgList.push(item)

          // 3. 照時間戳排序
          msgList.sort((a, b) => a.createdAt - b.createdAt)
        }
      }

      if (item.isEdited) {
        const editIndex = msgList.findIndex(i => i.id === item.id)
        if (editIndex) {
          msgList[editIndex].isEdited = true
          msgList[editIndex].content = item.content
        }
      }

      // 提示新訊息
      this.unreadMsgScroll(isNewMessage)
    },
    subscribeConv (convInfo) { // 訂閱會話
      if (this.currentSubscribeConvInfo && Object.keys(this.currentSubscribeConvInfo).length > 0) {
        if (this.currentSubscribeConvInfo.conversationId === convInfo.conversationId) {
          return
        }

        this.unsubscribeConv()
      }

      subscribeFn(convInfo)
      this.currentSubscribeConvInfo = convInfo
    },
    unsubscribeConv () { // 取消訂閱會話
      if (this.currentSubscribeConvInfo && Object.keys(this.currentSubscribeConvInfo).length > 0) {
        unsubscribe(this.currentSubscribeConvInfo)
        this.currentSubscribeConvInfo = {}
      }
    },
    unreadMsgScroll (isNewMessage) { // 未讀訊息
      if (this.isScrollAtBottom) {
        this.scrollToBottom += 1 // 卷軸捲到最底
        return
      }

      if (isNewMessage) {
        this.unreadMsgNum = Math.max(0, this.unreadMsgNum + 1)
      }
      this.scrollToBottom = 0
    },
    async connectUnassignConv (conv) { // 連接未分配對話
      await connectConvFn(conv.id)
    },
    async connectQueue (conv) { // 連接排隊對話
      await connectQueueFn(conv.customerId, conv.channelId)
      this.getConvAndRemoveFromList(conv.id, this.queueList, this.queueListIds)
    },
    ensureListsAndSetsSync () { // 確保列表和集合同步
      const syncListWithSet = (list, set) => {
        const newSet = new Set()
        let isSynced = true

        list.forEach((item) => {
          if (!set.has(item.id)) {
            isSynced = false
          }
          newSet.add(item.id)
        })

        if (newSet.size !== set.size) {
          isSynced = false
        }

        if (!isSynced) {
          set.clear()
          newSet.forEach(id => set.add(id))
        }
      }

      syncListWithSet(this.assignList, this.assignListIds)
      syncListWithSet(this.unassignList, this.unassignListIds)
      syncListWithSet(this.queueList, this.queueListIds)
      syncListWithSet(this.finishedList, this.finishedListIds)
    },
    async readConv (convId) { // 已讀對話
      await readConvFn(convId)
    },
    getConvSettingsStorage () { // 從localStorage取得對話設置相關值
      const config = localStorage.getItem('convListConfig')
      return config ? JSON.parse(config) : null
    },
    convSettingsStorage ({ // 對話設置相關值儲存至localStorage
      channelTypeValue = null,
      customerTagsValue = null,
      sortValue = null,
      setConvListValue = null,
      viewConvValue = null,
      currentTabValue = null,
      tabActiveMapValue = null,
      reloadConvList = true
    } = {}) {
      const config = JSON.parse(localStorage.getItem('convListConfig') || '{}')

      // 更新特定的值
      if (sortValue !== null) { config.sort = sortValue }
      if (channelTypeValue !== null) { config.channelType = channelTypeValue }
      if (customerTagsValue !== null) { config.customerTags = customerTagsValue }
      if (setConvListValue !== null) { config.setConvList = setConvListValue }
      if (viewConvValue !== null) { config.viewConv = viewConvValue }
      if (currentTabValue !== null) { config.currentTab = currentTabValue }
      if (tabActiveMapValue !== null) {
        config.tabActiveMap = Object.fromEntries(tabActiveMapValue)
      }

      localStorage.setItem('convListConfig', JSON.stringify(config))

      // 更新對話列表
      if (reloadConvList) {
        this.getConvListByTab()
      }
    }
  }
})
