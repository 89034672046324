import { defineStore } from 'pinia'
import { useUserInfo } from './useInfo'

export const useConversationInfo = defineStore('conversationInfo', {
  state: () => ({
  }),
  getters: {
  },
  actions: {
    // 處理引用訊息
    processReplyMsg (item, customerName) {
      const userInfo = useUserInfo()
      const { $i18n } = useNuxtApp()
      const anonymousText = $i18n.t('stores.conversation.anonymous')

      if (!item.replyMsgContent) { return }

      // 名稱 ---
      // 群聊 : 後端會回傳replyMsgSenderName，訪客名跟頭像"
      // 一般 : 客服: 客服名稱、訪客: 客服端帶訪客名稱、訪客端帶'來自我'
      // TG(有開匿名): 匿名
      if (item.replyMsgSenderAnonymous) {
        item.replyMsgSenderName = anonymousText
      } else if (!item.replyMsgSenderName) {
        if (item.replyMsgSenderId === item.customerId) {
          // 引用訪客
          item.replyMsgSenderName = customerName
        } else {
          // 引用客服
          const agentName = userInfo.agentUsersMap.get(item.replyMsgSenderId)?.username
          item.replyMsgSenderName = agentName || ''
        }
      }

      // 內容 ---
      item.replyMsgContent = stripHtml(item.replyMsgContent)
    }
  }
})
